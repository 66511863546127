import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Mobile from "../src/views/mobile";
import session from "../src/lib/session";
import "./scss/style.scss";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  // if (isMobile) {
  //   return <Mobile />
  // }
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
          <Route
            path="/"
            name="Home"
            render={(props) =>
              session ? <TheLayout {...props} /> : <Login {...props} />
            }
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
