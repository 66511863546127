import React from 'react';

function App() {
  return(
    <div className="bg-dark mobile-container">
      <h3 style={{ color: 'white', textAlign: 'center' }}>This page is not available on mobile</h3>
    </div>
  )
}

export default App;